var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"test"}},[_c('section',{staticClass:"section section-blog"},[_c('div',{staticClass:"columns is-mobile"},[_c('b-row',_vm._l((_vm.items.data),function(item,index){return _c('transition',{key:item.id,attrs:{"name":"fade","tag":"div"}},[_c('div',{staticClass:"card-item",class:(_vm.itemClass ? _vm.itemClass : 'col-sm-6 col-lg-4 col-xl-4') +
              (_vm.limit == 3 && index == 2 ? ' d-none d-md-block' : '') +
              (item.attributes.Intro.Color ? '' : ' no-color')},[_c('nuxt-link',{staticClass:"intro",attrs:{"to":{
                name: '/blog-detail',
                params: { id: item.attributes.Slug },
              }}},[_c('b-card',{style:({ backgroundColor: item.attributes.Intro.Color }),attrs:{"no-body":"","data-aos":"zoom-in","data-aos-offset":"100","data-aos-duration":"200"}},[_c('div',{staticClass:"img-holder text-center"},[(item.attributes.Intro.Image)?_c('nuxt-img',{attrs:{"src":_vm.$config.cdnURL +
                      item.attributes.Intro.Image.data.attributes.url,"format":"webp","loading":"lazy","alt":item.attributes.Title,"width":"503","height":"377"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-type"})],1),_vm._v(" "),_c('b-card-body',[(item.attributes.Created)?_c('span',{staticClass:"date"},[_c('span',{staticClass:"icon-cal-thin"}),_vm._v("\n                    "+_vm._s(_vm._f("formatDate")(item.attributes.Created)))]):_vm._e(),_vm._v(" "),_c('h2',[_vm._v(_vm._s(item.attributes.Title))])])],1)],1)],1)])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }