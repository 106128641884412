
import Vue, { PropOptions } from "vue";

export default Vue.extend({
  props: {
    items: {
      type: Object,
      required: false,
    } as PropOptions<any[]>,
    itemClass: {
      type: String,
      required: false,
    } as PropOptions<String>,
    limit: {
      type: Number,
      required: false,
    } as PropOptions<Number>,
  },
});
